.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top of other elements */
}

.modal-content {
  background-color: #fff;
  padding: 25px 32px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
  overflow: scroll;
  max-height: 94vh;
}

.modal h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.modal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
}

.modal .modal-content form {
  display: flex;
  flex-direction: column;
}

.modal .modal-content form div {
  margin-bottom: 15px;
}

.modal .modal-content form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.modal .modal-content form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.modal .modal-content form button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal .modal-content form button:hover {
  background-color: #45a049;
}

