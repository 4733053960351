.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
  }
  
  .cookie-consent p {
    margin: 0;
    padding: 0 10px;
    display: inline;
  }
  
  .cookie-consent .btn {
    margin: 0 5px;
  }
  