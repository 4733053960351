#booking h1 {
    font-weight: 900; font-size: 40px;
}
#booking input[type=checkbox] {
    height: auto;
}
#tnc-label {
    color: rgb(38, 127, 202);
}
@media all{
    hr{color:inherit;}
    button,input{font-size:100%;line-height:1.15;}
    button{-webkit-appearance:button;}
    h1,h2,h3,h4,h5,hr,p{margin:0;}
    button{background-color:transparent;background-image:none;}
    button:focus{outline:1px dotted;outline:5px auto -webkit-focus-ring-color;}
    *,:after,:before{border:0 solid #e5e7eb;}
    hr{border-top-width:1px;}
    img{border-style:solid;}
    input::placeholder{color:#9ca3af;}
    button{cursor:pointer;}
    h1,h2,h3,h4,h5{font-size:inherit;font-weight:inherit;}
    a{color:inherit;text-decoration:inherit;}
    button,input{padding:0;}
    img{display:block;vertical-align:middle;}
    img{max-width:100%;height:auto;}
    a{transition:all .2s ease-in-out;}
    .default-bg-color{background-color:#267fca!important;}
    .custom-green-button{font-size:16px;font-weight:700;color:#fff;padding:15px 25px;border-radius:8px;background-color:#267fca;transition:all .3s ease-in-out;}
    .custom-green-button:hover{margin-left:10px;}
    
    .custom-progress-2-container{max-width:1300px;margin:auto;padding:0 25px;}
    .custom-progress-2-container .left-side input{height:50px;outline:none;padding-left:15px;padding-right:15px;border:1px solid #cfd1d7;}
    .custom-progress-2-container .left-side .custom-row{margin-bottom:15px;}
    .custom-progress-2-container .left-side{width:65%;}
    .custom-progress-2-container .right-side{width:26%;}
    .custom-progress-2-container{margin-top:30px;}
    .custom-progress-2-container .right-side .custom-green-button{color:#267fca;border:2px solid #267fca;background-color:#fff;max-width:80%;display:flex;justify-content:center;margin:30px auto auto;transition:all .2s ease-in-out;}
    .custom-progress-2-container .right-side .custom-green-button:hover{color:#fff;background-color:#267fca;}
    .custom-progress-2-container .right-side .custom-border{border-top:1px solid #cfd1d7;border-bottom:1px solid #cfd1d7;}
    
    .custom-progress-2-container .right-side .content .title:before{content:"";width:12px;height:12px;border-radius:50%;border:4px solid #267fca;display:block;position:absolute;left:-31px;top:6px;}
    .custom-progress-2-container .right-side .image{max-width:60%;margin:auto;}
    .input-bg-gray{background-color:#cfd1d7!important;}
    .multiselect{width:100%;cursor:pointer;}
    .multiselect{position:relative;}
    .multiselect input{height:auto!important;margin-left:5px;margin-right:5px;}
    input::placeholder{font-weight:400;color:#999!important;}
    .blue-checkbox{background-color:#267fca;}
    .blue-checkbox .blue-cb{width:30px;border:1px solid #fff!important;}
    .payment-notice{background-color:#ecedef;}
    @media only screen and (max-width:1024px){
    .custom-progress-2-container{margin-top:30px;}
    }
    @media only screen and (max-width:767px){
    .custom-progress-2-container .left-side,.custom-progress-2-container .right-side{width:100%;}
    }
    .loading-notes{white-space:pre-line;}
    .rounded-md{border-radius:.375rem;}
    .rounded-lg{border-radius:.5rem;}
    .flex{display:flex;}
    .flex-row-reverse{flex-direction:row-reverse;}
    .flex-col{flex-direction:column;}
    .items-center{align-items:center;}
    .justify-between{justify-content:space-between;}
    .font-light{font-weight:300;}
    .font-medium{font-weight:500;}
    .font-bold{font-weight:700;}
    .text-sm{font-size:.875rem;line-height:1.25rem;}
    .text-base{font-size:1rem;line-height:1.5rem;}
    .text-lg{font-size:1.125rem;}
    .text-lg,.text-xl{line-height:1.75rem;}
    .text-xl{font-size:1.25rem;}
    .leading-6{line-height:1.5rem;}
    .mt-2{margin-top:.5rem;}
    .mr-2{margin-right:.5rem;}
    .mb-2{margin-bottom:.5rem;}
    .mt-3{margin-top:.75rem;}
    .mb-3{margin-bottom:.75rem;}
    .ml-3{margin-left:.75rem;}
    .mt-4{margin-top:1rem;}
    .mr-4{margin-right:1rem;}
    .mb-4{margin-bottom:1rem;}
    .mt-5{margin-top:1.25rem;}
    .mr-5{margin-right:1.25rem;}
    .mt-6{margin-top:1.5rem;}
    .mt-8{margin-top:2rem;}
    .mb-8{margin-bottom:2rem;}
    .mt-10{margin-top:2.5rem;}
    .mr-10{margin-right:2.5rem;}
    .mb-0\.5{margin-bottom:.125rem;}
    .p-4{padding:1rem;}
    .p-6{padding:1.5rem;}
    .px-6{padding-left:1.5rem;padding-right:1.5rem;}
    .py-7{padding-top:1.75rem;padding-bottom:1.75rem;}
    .py-0\.5{padding-top:.125rem;padding-bottom:.125rem;}
    .pt-2{padding-top:.5rem;}
    .pb-2{padding-bottom:.5rem;}
    .pt-3{padding-top:.75rem;}
    .pl-9{padding-left:2.25rem;}
    .relative{position:relative;}
    *{--tw-shadow:0 0 transparent;}
    .shadow-2xl{box-shadow:0 0 transparent,0 0 transparent,var(--tw-shadow);box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);}
    .shadow-2xl{--tw-shadow:0 25px 50px -12px rgba(0,0,0,0.25);}
    *{--tw-ring-inset:var(--tw-empty, );--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgba(59,130,246,0.5);--tw-ring-offset-shadow:0 0 transparent;--tw-ring-shadow:0 0 transparent;}
    .text-center{text-align:center;}
    .text-white{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));}
    .text-red-500{--tw-text-opacity:1;color:rgba(239,68,68,var(--tw-text-opacity));}
    .text-green-primary{--tw-text-opacity:1;color:rgba(41,166,4,var(--tw-text-opacity));}
    .uppercase{text-transform:uppercase;}
    .w-full{width:100%;}
    @media (min-width:640px){
    .sm\:flex{display:flex;}
    .sm\:mt-0{margin-top:0;}
    .sm\:mr-3{margin-right:.75rem;}
    .sm\:ml-3{margin-left:.75rem;}
    .sm\:pr-2{padding-right:.5rem;}
    .sm\:pl-2{padding-left:.5rem;}
    .sm\:w-1\/2{width:50%;}
    }
    @media (min-width:768px){
    .md\:flex{display:flex;}
    .md\:flex-row{flex-direction:row;}
    .md\:text-lg{font-size:1.125rem;line-height:1.75rem;}
    .md\:mt-0{margin-top:0;}
    .md\:mb-0{margin-bottom:0;}
    .md\:p-12{padding:3rem;}
    }
    @media (min-width:1024px){
    .lg\:flex{display:flex;}
    .lg\:mt-0{margin-top:0;}
    }
    *,:after,:before{-webkit-box-sizing:border-box;box-sizing:border-box;}
    hr{-webkit-box-sizing:content-box;box-sizing:content-box;height:0;overflow:visible;}
    h1,h2,h3,h4,h5{margin-top:0;margin-bottom:.5em;color:rgba(0,0,0,.85);font-weight:500;}
    p{margin-top:0;margin-bottom:1em;}
    input[type=text]{-webkit-appearance:none;}
    b{font-weight:bolder;}
    a{color:#1890ff;text-decoration:none;background-color:transparent;outline:none;cursor:pointer;-webkit-transition:color .3s;transition:color .3s;-webkit-text-decoration-skip:objects;}
    a:hover{color:#40a9ff;}
    a:active{color:#096dd9;}
    a:active,a:focus,a:hover{text-decoration:none;outline:0;}
    img{vertical-align:middle;border-style:none;}
    a,button,input:not([type=range]),label{-ms-touch-action:manipulation;touch-action:manipulation;}
    button,input{margin:0;color:inherit;font-size:inherit;font-family:inherit;line-height:inherit;}
    button,input{overflow:visible;}
    button{text-transform:none;}
    button{-webkit-appearance:button;}
    input[type=checkbox]{-webkit-box-sizing:border-box;box-sizing:border-box;padding:0;}
    ::selection{color:#fff;background:#1890ff;}
    }
    .parcel-booking-form {
        width: 80%;
        margin: 0 auto;
        display: block;
    }

    
   
    .custom-progress-2-container  .parcel-booking-form select,  .custom-progress-2-container  .parcel-booking-form  input:not([type="checkbox"]){height:50px;outline:none;padding-left:15px;padding-right:15px;border:1px solid #cfd1d7;background-color: #fff;}