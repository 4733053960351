.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    display: flex;
    align-items: center;
  }
  
  .card-header img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 15px;
  }
  
  .card-header h2 {
    font-size: 1.5em;
    color: #34495e;
  }
  .shipment-details h3 {
    font-size: 1.2em;
    color: #2980b9;
    margin-bottom: 10px;
  }
  .price-options {
    list-style: none;
  }
  
  .price-options li {
    padding: 10px 0;
    border-bottom: 1px solid #ecf0f1;
    display: flex;
    justify-content: space-between;
  }
  
  .price-options li:last-child {
    border-bottom: none;
  }
  
  .price-options span {
    font-weight: bold;
    color: #2c3e50;
  }
  .list-group-item {
    border: none !important;
  }
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }