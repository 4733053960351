	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/01/2019 13:45
  	*/


@font-face {
  font-family: "Flaticon";
  src: url("../assets/fonts/flaticon/font/Flaticon.eot");
  src: url("../assets/fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/flaticon/font/Flaticon.woff") format("woff"),
       url("../assets/fonts/flaticon/font/Flaticon.ttf") format("truetype"),
       url("../assets/fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-wedding-car:before { content: "\f100"; }
.flaticon-car:before { content: "\f101"; }
.flaticon-suv:before { content: "\f102"; }
.flaticon-transportation:before { content: "\f103"; }
.flaticon-route:before { content: "\f104"; }
.flaticon-handshake:before { content: "\f105"; }
.flaticon-rent:before { content: "\f106"; }
.flaticon-dashboard:before { content: "\f107"; }
.flaticon-pistons:before { content: "\f108"; }
.flaticon-car-seat:before { content: "\f109"; }
.flaticon-backpack:before { content: "\f10a"; }
.flaticon-diesel:before { content: "\f10b"; }