.containerPay {
    margin: 20px auto;
    width: 800px;
    padding: 30px
}
#payment-section {
    padding: 70px 0;
    display: inline;
  }
.card.box1 {
    width: 350px;
    /* height: 500px; */
    background-color: #3ecc6d;
    color: #baf0c3;
    border-radius: 0
}

.card.box2 {
    width: 450px;
    /* height: 690px; */
    background-color: #ffffff;
    border-radius: 0
}

#payment-section .text {
    font-size: 13px
}

.box2 .btn.btn-primary.bar {
    width: 20px;
    background-color: transparent;
    border: none;
    color: #3ecc6d
}

.box2 .btn.btn-primary.bar:hover {
    color: #baf0c3
}

.box1 .btn.btn-primary {
    background-color: #57c97d !important;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd !important
}

.box1 .btn.btn-primary:hover {
    background-color: #f6f8f7;
    color: #57c97d
}

#payment-section .btn.btn-success {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none
}

#payment-section .nav.nav-tabs {
    border: none;
    border-bottom: 2px solid #ddd
}

#payment-section .nav.nav-tabs .nav-item .nav-link {
    border: none;
    color: black;
    border-bottom: 2px solid transparent;
    font-size: 14px
}

#payment-section .nav.nav-tabs .nav-item .nav-link:hover {
    border-bottom: 2px solid #3ecc6d;
    color: #05cf48
}

#payment-section .nav.nav-tabs .nav-item .nav-link.active {
    border: none;
    border-bottom: 2px solid #3ecc6d
}

#payment-section  .form-control {
    border: none !important;
    border-bottom: 1px solid #ddd !important;
    box-shadow: none !important;
    height: 25px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  padding: 17px 0 15px 0;
    letter-spacing: 1.5px !important;
    border-radius: 0 !important
}
.inputWithIcon .card-element {
    padding: 15px 0 24px 0 !important;
    
}

.inputWithIcon .card-element input {
    font-weight: 400 !important;
    font-size: 12px !important;
}
.inputWithIcon {
    position: relative
}

#payment-section  img {
    width: 50px;
    height: 20px;
    object-fit: cover
}

.inputWithIcon span {
    position: absolute;
    right: 0px;
    bottom: 20px;
    color: #57c97d;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px
}

#payment-section .form-control:focus {
    box-shadow: none;
    border-bottom: 1px solid #ddd
}

#payment-section  .btn-outline-primary {
    color: black;
    background-color: #ddd;
    border: 1px solid #ddd
}

#payment-section  .btn-outline-primary:hover {
    background-color: #05cf48;
    border: 1px solid #ddd
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #baf0c3;
    background-color: #3ecc6d;
    box-shadow: none;
    border: 1px solid #ddd
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-radius: 50px;
    margin-right: 20px
}

#payment-section  form {
    font-size: 14px
}
.payment-form button {
    background-color: #3ecc6d !important;
}
#payment-section  form .btn.btn-primary {
    width: 100%;
    height: 45px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3ecc6d !important;
    border: 1px solid #ddd !important;
}

#payment-section  form .btn.btn-primary:hover {
    background-color: #05cf48
}

@media (max-width:750px) {
    .containerPay {
        padding: 10px;
        width: 100%
    }

    .text-green {
        font-size: 14px
    }

    .card.box1,
    .card.box2 {
        width: 100%
    }

    #payment-section .nav.nav-tabs .nav-item .nav-link {
        font-size: 12px
    }
}

.card-title {
    padding: 15px 0 14px 0 !important;
  }

  .country-drop {
    padding: 0px 0 5px 0 !important;
    margin-top: 5px;
  }
  .country-select-color {
    color: #ccc !important;
  }

  select.form-control {
    color: gray !important; /* Default color for the select text */
  }
  select option {
    color: #000000 !important; /* Color for the actual options */
  }
  